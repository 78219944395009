.boxWrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    width: auto;
    height: auto;
    background-color: white;
    z-index: 1;
    top: 0%;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 10px;
}
