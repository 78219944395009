button.gm-ui-hover-effect {
    visibility: hidden;
}

.gm-style .gm-style-iw-c {
     padding: 5px !important;
     max-width: 500px !important;
     max-height: 326px !important;
     min-width: 0px !important;
     position: absolute;
     box-sizing: border-box;
     overflow: hidden;
     top: 0;
     left: 0;
     transform: translate(-50%,-100%);
     border-radius: 0px;
     border: 1px solid black;
     box-shadow: 0 2px 7px 1px rgba(0,0,0,0.3);
 }

 .gm-style-iw-d {
    overflow: hidden !important;
}